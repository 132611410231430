import React from "react";
import { ErrorMessage, Field, useFormikContext } from "formik";
import { translate } from "react-i18nify";

export const AddressForm = () => {
  const formik = useFormikContext();
  return (
    <form className="" onSubmit={formik.handleSubmit}>
      <div className="form-row mt-4">
        <div className="form-group col-md-6">
          <label className="text-small">
            {translate("addressForm.street")}
          </label>
          <Field
            name="address.street"
            type="text"
            className="form-control form-control-sm"
            placeholder="Street"
          />
          <ErrorMessage
            name="address.street"
            component="div"
            className="invalid-feedback d-block"
          />
        </div>
        <div className="form-group col-md-6">
          <label className="text-small">
            {translate("addressForm.houseNo")}
          </label>
          <Field
            name="address.houseNumber"
            type="text"
            className="form-control form-control-sm"
            placeholder="House number"
          />
          <ErrorMessage
            name="address.houseNumber"
            component="div"
            className="invalid-feedback d-block"
          />
        </div>
      </div>
      <div className="form-group">
        <label className="text-small">{translate("addressForm.address")}</label>
        <Field
          type="text"
          name="address.address"
          className="form-control form-control-sm"
          placeholder="Address"
        />
        <ErrorMessage
          name="address.address"
          component="div"
          className="invalid-feedback d-block"
        />
      </div>
      <div className="form-row">
        <div className="form-group col-md-6">
          <label className="text-small">
            {translate("addressForm.country")}
          </label>
          <input
            className="form-control form-control-sm"
            value="Nigeria"
            readOnly
          />
        </div>
        <div className="form-group col-md-6">
          <label className="text-small">{translate("addressForm.state")}</label>
          <input
            className="form-control form-control-sm"
            value="Lagos"
            readOnly
          />
        </div>
      </div>
    </form>
  );
};
